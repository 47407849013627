//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faTimes, faAngleLeft } from '@fortawesome/pro-regular-svg-icons';

export default {
  name: 'FileSearchSidebar',
  props: {
    id: String,
    title: String,
    hideAllSidebar: String,
    backButton: Boolean,
    shown: Boolean,
    hidden: Boolean,
  },
  data() {
    return {
      faTimes,
      faAngleLeft,
    };
  },
  methods: {
    emitShown() {
      this.$emit('shown');
      // I try to do it with head() > bodyAttrs > class - but it dont work inside layout
      // disabled body scroll when sidebar is opened
      document.body.classList.add('modal-open');
    },
    emitHidden() {
      this.$emit('hidden');
      document.body.classList.remove('modal-open');
    },
  },
};
